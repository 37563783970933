import React from 'react'

function noAccess() {
    return (
        <div style={{display:'flex',justifyContent:'flex-start',backgroundColor:'aliceblue',flexDirection:'column',alignItems:'center',width:'100%',height:'100vh'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'flex-start',height:700,width:'80%',backgroundColor:'#fff',marginTop:100,borderRadius:30,marginBottom:50}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                        <img src={require('../Image/scan.gif')} style={{width:'80%',marginBottom:20}}/>
                        <h1 style={{margin:0,textAlign:'center',color:'darkslategray',fontWeight:'300'}}>Waktu Pemilihan Telah Selesai, Terima Kasih.</h1>
                    </div>
                </div>
            </div>
    )
}

export default noAccess
