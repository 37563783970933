import React, { Component,useState, useContext,useEffect } from 'react'
import axios from 'axios'
import {GlobalStateContext} from './globalDataContext'
import useGlobalState from "./globalDataContext";
import { MdCheckCircle } from "react-icons/md";
import { MdDeveloperBoard } from "react-icons/md";
import { MdVerifiedUser } from "react-icons/md";
import { GoVerified } from "react-icons/go";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import _ from "lodash";
// import DynamicFont from 'react-dynamic-font';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

function CalonItem({nama,no}){
    return(
        <div style={{width:'100%',height:70,display:'flex',flexDirection:'row',borderWidth:2,borderColor:'crimson',borderBottomWidth:1.5,borderBottomStyle:'solid',borderColor:'whitesmoke'}}>
            <div style={{flex:1,height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                <h5 style={{fontWeight:'700',fontFamily:'Alata'}}>
                    No.{no}
                </h5>
            </div>
            <div style={{flex:4,height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                {/* <ScaleText> */}
                    <h4 style={{fontWeight:'400',color:'#8d8d90',fontFamily:'Alata',fontSize:13}}>
                        {nama}
                    </h4>
                    {/* <div style={{width:200,lineHeight:30,overflow:'hidden',fontWeight:'400',color:'#8d8d90',fontFamily:'Alata'}}>
                        <DynamicFont content={nama} />
                    </div> */}

                {/* </ScaleText> */}
            </div>
            <div style={{flex:1,height:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                <GoVerified size={23} color={'deepskyblue'}/>
            </div>
        </div>
    )
}
function HasilItem({nanona,voteCount,nama,nomor}){
    // console.log(nanona)
    let warna = "gainsboro";
    if (nanona >= 50) {
      warna = "deepskyblue";
    } else if (nanona >= 25 && nanona < 50) {
      warna = "crimson";
    } else if (nanona < 25) {
      warna = "#ff808a";
    }
    return(
        <div style={{width:'100%',height:50,display:'flex',flexDirection:'row',borderWidth:2,borderColor:'crimson',borderBottomWidth:0,borderBottomStyle:'solid',borderColor:'whitesmoke'}}>
            <div style={{flex:1,height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                <h5 style={{fontWeight:'700'}}>
                    No Urut.{nomor}
                </h5>
            </div>
            <div style={{flex:4,height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                    <h4 style={{fontWeight:'400',color:'#8d8d90',fontFamily:'Alata',fontSize:13}}>
                        {nama}
                    </h4>
            </div>
            <div style={{flex:1,height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <MdVerifiedUser size={20} color={warna}/>
                <h4 style={{fontWeight:'400',color:warna,fontFamily:'Alata',marginLeft:10}}>
                    {voteCount}
                </h4>
            </div>
            <div style={{flex:3,height:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                {/* <MdCheckCircle size={30} color={'deepskyblue'}/> */}
                <div style={{height:5,width:'90%',borderRadius:2.5,backgroundColor:'gainsboro',marginRight:10,display:'flex',justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                    <div style={{height:5,width:`${nanona}%`,borderRadius:2.5,backgroundColor:warna}}>
                    </div>
                </div>
                <h4 style={{fontWeight:'600',color:warna,fontFamily:'Alata'}}>
                    {nanona}%
                </h4>
            </div>
        </div>
    )
}

function Setting(){
    const [stateReducer, dispatch] = useContext(GlobalStateContext);
    const   [state,setState]    =   useState({
        a:false,b:false,c:false,
        uname:null,
        password:null,
        passwordConf:null,
        tokenServer:null,
        count:0,
        hasil:[],
    })
    const [dpm,setDpm]  =   useState([])
    const [hasilPresma,setHPresma]  =   useState([])
    const [hasilDPM,setHDpm]  =   useState([])
    const   [presma,setPresma]  =   useState([])
    let history =   useHistory()
    const getPresma =   ()=>{
        axios.get(stateReducer.usedUrl+'/api/presiden').then((res)=>{
            console.log(res.data)
            setPresma(res.data)
        }).catch(err=>{
            console.log({err})
        })
    }
    const getDpm =   ()=>{
        axios.post(stateReducer.usedUrl+'/api/dpm',{fakultas:stateReducer.datatps.data.fakultas}).then((res)=>{
            console.log(res.data)
            setDpm(res.data)
        }).catch(err=>{
            console.log({err})
        })
    }
    useEffect(()=>{
        if(stateReducer.datatps==undefined){
            setState({...state,a:false})
        }else{
            getDpm()
            getPresma()
            axios.get(stateReducer.usedUrl+'/api/countVote?token='+stateReducer.datatps.token).then(res=>{
                console.log(state);
                setState({...state,count:res.data,a:true})
            })
            .catch(err=>{
                console.log({err})
            })
        }
        console.log(state.a,stateReducer.datatps)
    },[])
    const clear =   ()=>{
        dispatch({type:'RESET',payload:{data:'hebat'}})
        setState({...state,a:false})
        console.log(state)
        // getPresma()
    }
    const login = ()=>{
        // this.setState({a:!this.state.a})
        axios.post(stateReducer.usedUrl+'/api/login',{username:state.uname,password:state.password}).then(res=>{
            console.log(res.data)
            if(res.data.message=='Login Success'){
                alert('Berhasil')
                dispatch({type:'ADD_DATA_TPS',payload:res.data})
                setState({...state,a:true})
            }else{
                alert('Terjadi Kesalahan')
            }
        }).catch(err=>{
            console.log({err})
        })
    }
    const backup = () => {
        axios.post('http://' + stateReducer.usedUrl + '/api/createBackup', {
                token: stateReducer.datatps.token
            })
            .then((res) => {
                console.log(res.data)
                if (res.data.length == 0) {
                    alert('Belum Ada Vote Masuk')
                } else {
                    console.log(res.data)
                    const tempPresma = res.data.filter((item) => {
                        return item.kategori == 1
                    })
                    const tempDpm = res.data.filter((item) => {
                        return item.kategori == 2
                    })
                    const groupedPresma = _.groupBy(tempPresma, item => item.calon_id);
                    const groupedDpm = _.groupBy(tempDpm, item => item.calon_id);
                    const kpresma = Object.keys(groupedPresma)
                    const kdpm = Object.keys(groupedDpm)
                    // console.log(tempPresma,tempDpm)
                    // console.log(groupedPresma,kpresma)
                    const apresma = []
                    const adpm = []
                    kpresma.map(item => {
                        const res = presma.filter(i => {
                            return i.id == item
                        })
                        console.log(groupedPresma[item].length)
                        if (res.length == 0) {

                        } else {
                            apresma.push({
                                nama: res[0].nama,
                                nomor: res[0].nomor,
                                voteCount: groupedPresma[item].length,
                                percent: groupedPresma[item].length / tempPresma.length * 100
                            })
                        }
                    })
                    kdpm.map(item => {
                        const resa = dpm.filter(i => {
                            return i.id == item
                        })
                        // console.log(res)
                        if (resa.length == 0) {

                        } else {
                            adpm.push({
                                nama: resa[0].nama,
                                nomor: resa[0].nomor,
                                voteCount: groupedDpm[item].length,
                                percent: groupedDpm[item].length / tempPresma.length * 100
                            })
                        }
                    })
                    // console.log(adpm)
                    setHPresma(apresma)
                    setHDpm(adpm)
                    console.log('hasil ==>', hasilPresma, hasilDPM)
                    setState({
                        ...state,
                        b: true,c:false,
                        hasil:res.data,
                    })
                    // sendHasil()
                }
            })
    }
    const sendHasil = ()=>{
        console.log(state.hasil)
        // setState({...state,c:true})
        // var person = prompt("Konfirmasi Password.", "");
        if(state.passwordConf!==null){
            axios.post('http://pemirama.untan.ac.id/pemirama-untan/public/api/login',{username:stateReducer.datatps.data.username,password:state.passwordConf}).then(res=>{
                console.log(res.data)
                setState({...state,tokenServer:res.data.token})
                if(res.data.token){
                        axios.post('http://pemirama.untan.ac.id/pemirama-untan/public/api/uploadBackup',{count:state.count,data:state.hasil,token:res.data.token}).then(res=>{
                            console.log(res.data)
                            alert('BackUp Terkirim !!!')
                            // setState({...state,c:false})
                            history.push('pdf',{count:state.count,hasilPresma:hasilPresma,hasilDPM:hasilDPM,token:state.tokenServer})
                        }).catch(err=>{
                            console.log({err})
                        })
                }
            }).catch(err=>{
                console.log({err})
            })
        }else{
            alert('Data Belum di BackUp.')
        }
       
    }
    const renderHasilPresma   =   ()=>{
        if(!state.b){
            return(
                <div style={{flex:4,height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
                    <h4 style={{fontWeight:'400',fontFamily:'Alata'}}>
                        Hasil Belum Dapat Diproses
                    </h4>
                </div>
            )
        }else{
            return(
                <div style={{flex:4,display:'flex',height:'100%',flexDirection:'column',padding:30}}>
                    <div style={{}}>
                        <h4 style={{fontWeight:'700',marginTop:0,marginBottom:10}}>
                            Hasil Presma-Wapresma
                        </h4>
                    </div>
                    <div style={{flex:3,width:'100%',display:'flex',flexDirection:'column'}}>
                        {/* <HasilItem nanona={63} voteCount={243}/>
                        <HasilItem nanona={10} voteCount={143}/>
                        <HasilItem nanona={27} voteCount={87}/> */}
                        {hasilPresma.map((item,key)=>{
                                return(
                                    <HasilItem nanona={item.percent.toFixed(0)} voteCount={item.voteCount} nama={item.nama} nomor={item.nomor} key={key}/>
                                )
                            })
                        }
                    </div>
                    <div style={{flex:2,width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                        {/* <button 
                            onClick={()=>{
                                console.log('cetak');
                            }}
                            style={{padding:0,width:200,height:50,backgroundColor:'deepskyblue',color:'#fff',borderRadius:8,fontSize:17,fontWeight:'600',borderWidth:0}}>
                                Cetak Hasil
                        </button>  */}
                    </div>
                </div>
            )
        }
    }
    const renderHasilDPM   =   ()=>{
        if(!state.b){
            return(
                <div style={{flex:4,height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
                    <h4 style={{fontWeight:'400',fontFamily:'Alata'}}>
                        Hasil Belum Dapat Diproses
                    </h4>
                </div>
            )
        }else{
            return(
                <div style={{flex:4,display:'flex',height:'100%',flexDirection:'column',padding:30}}>
                    <div style={{}}>
                        <h4 style={{fontWeight:'700',marginTop:0,marginBottom:10}}>
                            Hasil DPM
                        </h4>
                    </div>
                    <div style={{flex:3,width:'100%',display:'flex',flexDirection:'column'}}>
                    {hasilDPM.length==0 ? (
                            <div style={{flex:4,height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
                                <h4 style={{fontWeight:'400',fontFamily:'Alata'}}>
                                    Data Masih Kosong
                                </h4>
                            </div>
                    ):(
                        hasilDPM.map((item,key)=>{
                            console.log(item)
                                return(
                                    <HasilItem nanona={item.percent.toFixed(0)} voteCount={item.voteCount} nama={item.nama} nomor={item.nomor} key={key}/>
                                )
                        })
                    )
                    }
                    </div>
                    <div style={{flex:2,width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                        {/* <button 
                            onClick={()=>{
                                console.log('cetak');
                            }}
                            style={{padding:0,width:200,height:50,backgroundColor:'#83d682',color:'#fff',borderRadius:8,fontSize:17,fontWeight:'600',borderWidth:0}}>
                                Cetak Hasil
                        </button>  */}
                    </div>
                </div>
            )
        }
    }
    Modal.setAppElement('#root')
    const customStyles = {
        content : {
          height:200,width:'50%',
          borderRadius:30,
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
      };
    const renderInput   =   ()=>{
        if(!state.a){
            return(
                <div style={{display:'flex',flex:1,flexDirection:'column',padding:0,backgroundColor:'#f6f4fb',alignItems:'center',justifyContent:'center'}}>
                    <div style={{width:'80%',height:50,display:'flex',flexDirection:'row',marginTop:40,alignItems:'flex-start'}}>
                        <div style={{width:'70%',height:50,backgroundColor:'#fff',borderRadius:20,display:'flex',flexDirection:'row'}}>
                            <div style={{flex:9,height:'97%',paddingLeft:30,paddingRight:30,display:'flex',justifyContent:'space-between',alignItems:'center',borderBottomWidth:0,borderBottomStyle:'solid',borderColor:'darkslategray'}}>
                                <div style={{flex:1,display:'flex',flexDirection:'row',height:'100%',alignItems:'center'}}>
                                    <MdDeveloperBoard size={30} color={'#ff808a'}/>
                                    <h1 style={{fontSize:18,fontWeight:'700',color:'dimgray',marginLeft:10,fontFamily:'Alata'}}>
                                        Dashboard TPS
                                    </h1>
                                </div>
                                <h1 style={{fontSize:17,fontWeight:'400',color:'dimgray',fontFamily:'Alata'}}>
                                    Jumlah Pemilih
                                </h1>
                            </div>
                            <div style={{flex:2,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#ff808a',borderTopRightRadius:20,borderBottomRightRadius:20}}>
                                <h1 style={{fontSize:22,fontWeight:'700',color:'#fff',fontFamily:'roboto'}}>
                                    {state.count}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'80%',height:260,backgroundColor:'#f9e5e9',borderRadius:30,marginTop:40,display:'flex',flexDirection:'row'}}>
                        <div style={{flex:2,flexDirection:'column',justifyContent:'center',display:'flex', width:'100%',alignItems:'flex-start',height:'100%',paddingLeft:80,paddingTop:15,paddingBottom:20}}>
                            <div style={{flex:1}}>
                                {/* <ScaleText> */}
                                    <h1 style={{fontSize:25,fontWeight:'700',color:'#e4717d',marginTop:10}}>
                                        Hai, Ini Station <br/>
                                        {/* {stateReducer.datatps.data.name} */}
                                    </h1>
                                    <h3 style={{color:'#4c4050',fontWeight:'400',fontFamily:'Alata'}}>
                                        Halaman ini berfungsi agar Petugas dapat melakukan Aksi backup dan rekap Data Hasil Perhitungan Pemirama UNTAN.
                                    </h3>
                                {/* </ScaleText> */}
                            </div>
                            <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center',marginTop:0}}>
                                <button 
                                    onClick={()=>{
                                        console.log('clear');
                                        clear()
                                        // sendHasil()
                                    }}
                                    style={{padding:0,width:120,height:50,backgroundColor:'#f9e5e9',color:'dodgerblue',borderRadius:8,fontSize:17,fontWeight:'600',marginTop:5,borderWidth:2,borderColor:'#5f80f5'}}>
                                        Clear
                                </button> 
                                <button 
                                    onClick={()=>{
                                        console.log('clear');
                                        // backup()
                                        // sendHasil()
                                        setState({...state,c:true})
                                        // dispatch({type:'RESET'})
                                    }}
                                    style={{padding:0,width:200,height:50,backgroundColor:'#ff808a',color:'#fff',borderRadius:8,fontSize:17,fontWeight:'600',marginTop:5,marginLeft:15,borderWidth:0}}>
                                        Create Backup
                                </button> 
                                <button 
                                    onClick={()=>{
                                        console.log('clear');
                                        // dispatch({type:'RESET'})
                                        sendHasil()
                                    }}
                                    style={{padding:0,width:150,height:50,backgroundColor:'deepskyblue',color:'#fff',borderRadius:8,fontSize:17,fontWeight:'600',marginTop:5,marginLeft:15,borderWidth:0}}>
                                        Cetak Hasil
                                </button>
                            </div>
                        </div>
                        <div style={{flex:2,flexDirection:'column',justifyContent:'center',display:'flex', width:'100%',alignItems:'center',height:'100%'}}>
                            <img src={require('../Image/clip-education.png')} style={{height:'150%',width:'70%',marginTop:-100,objectFit:'cover'}}/>
                        </div>
                    </div>
                    <div style={{width:'80%',display:'flex',flexDirection:'row',marginTop:50,marginBottom:40}}>
                        <div style={{flex:1,display:'flex',paddingRight:30,}}>
                            <div style={{width:'100%',height:650,display:'flex',flexDirection:'column',backgroundColor:'#fff',borderRadius:30,paddingLeft:30,paddingRight:30}}>
                                    <div style={{flex:1,width:'100%'}}>
                                        <div style={{}}>
                                            <h4 style={{fontWeight:'700'}}>
                                                Calon Presma Wapresma
                                            </h4>
                                        </div>
                                        <div style={{flex:2,width:'100%',display:'flex',flexDirection:'column'}}>
                                            {presma.map((item)=>{
                                                return(
                                                    <CalonItem nama={item.nama} no={item.nomor} key={item.id}/>
                                                )
                                            })}
                                            {/* {listPresma} */}
                                        </div>
                                    </div>
                                    <div style={{flex:2,width:'100%'}}>
                                        <div style={{}}>
                                            <h4 style={{fontWeight:'700'}}>
                                                Calon DPM
                                            </h4>
                                        </div>
                                        <div style={{flex:2,width:'100%',display:'flex',flexDirection:'column'}}>
                                            {dpm.map((item)=>{
                                                return(
                                                    <CalonItem nama={item.nama} no={item.nomor} key ={item.id}/>
                                                )
                                            })}
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div style={{flex:3,display:'flex',flexDirection:'column'}}>
                            <div style={{flex:1, width:'100%',display:'flex',flexDirection:'row',backgroundColor:'#fff',borderRadius:30,padding:0,marginBottom:20}}>
                                {renderHasilPresma()}
                                <div style={{flex:2,display:'flex',height:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <img src={require('../Image/Degital_marketing_PNG.png')} style={{height:250}}/>
                                </div>
                            </div>
                            <div style={{flex:1, width:'100%',display:'flex',flexDirection:'row',backgroundColor:'#fff',borderRadius:30}}>
                                {renderHasilDPM()}
                                <div style={{flex:2,display:'flex',height:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <img src={require('../Image/SEO_PNG.png')} style={{height:250}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                    isOpen={state.c}
                    style={customStyles}
                    >
                        <div>
                            <h3 style={{color:'dodgerblue'}}>
                                Password To Confirm
                            </h3>
                            <input
                            onChange={e=>{setState({...state,passwordConf:e.target.value})}}
                            type='password' name='password' style={{paddingLeft:0,fontSize:15,height:50,width:'100%',backgroundColor:'#fff',borderWidth:2,borderColor:'gainsboro',borderStyle:'solid',borderRadius:10,marginLeft:-2}}/>
                        </div>
                        <button 
                            onClick={()=>{
                                console.log('clear');
                                // clear()
                                setState({...state,c:false})
                                // sendHasil()
                            }}
                            style={{padding:0,width:120,height:50,backgroundColor:'darkslategray',color:'#fff',borderRadius:8,fontSize:17,fontWeight:'600',marginTop:5,borderWidth:0,borderColor:'#5f80f5',position:'absolute',right:150}}>
                                Ndak Jadi
                        </button>
                        <button 
                            onClick={()=>{
                                console.log('clear');
                                // clear()
                                backup()
                                // sendHasil()
                            }}
                            style={{padding:0,width:120,height:50,backgroundColor:'crimson',color:'#fff',borderRadius:8,fontSize:17,fontWeight:'600',marginTop:5,borderWidth:0,borderColor:'#5f80f5',right:20,position:'absolute'}}>
                                Gas Keun
                        </button> 
                    </Modal>
                </div>
            )
        }
    }
    const renderLogin   =   ()=>{
        if(state.a){
            return(
                <div style={{borderRadius:10,borderWidth:2,borderColor:'gainsboro',borderStyle:'solid',width:'50%',height:400,flexDirection:'column',display:'flex',paddingLeft:20,paddingRight:20}}>
                <div style={{marginTop:20}}>
                    <div>
                        <h3 style={{color:'dodgerblue'}}>
                            TPS Terdaftar
                        </h3>
                        <input 
                        onChange={e=>{setState({...state,uname:e.target.value})}}
                        type='text' name='nim' style={{paddingLeft:0,fontSize:15,height:50,width:'100%',backgroundColor:'#fff',borderWidth:2,borderColor:'gainsboro',borderStyle:'solid',borderRadius:8,marginLeft:-2}}/>
                    </div>
                    <div>
                        <h3 style={{color:'dodgerblue'}}>
                            Password
                        </h3>
                        <input
                        onChange={e=>{setState({...state,password:e.target.value})}}
                        type='password' name='password' style={{paddingLeft:0,fontSize:15,height:50,width:'100%',backgroundColor:'#fff',borderWidth:2,borderColor:'gainsboro',borderStyle:'solid',borderRadius:8,marginLeft:-2}}/>
                        {/* <Link to='/dashboard'> */}
                            <button 
                            onClick={()=>{
                                console.log('test');
                                login()
                            }}
                            style={{padding:0,width:'100%',height:50,marginTop:30,backgroundColor:'dodgerblue',color:'white',borderRadius:8,fontSize:16}}>
                                Login
                            </button>  
                        {/* </Link>                           */}
                    </div>
                    {/* <div>
                        <button style={{padding:0,width:'100%',height:50}}>
                            Login
                        </button>
                    </div> */}
                </div>
                </div>
            )
        }
    }
    return (
        <div>
            {renderInput()}
            {renderLogin()}
        </div>
    )
}

export default Setting