import React, { Component,useEffect,useState,useContext,useCallback } from 'react'
import axios from 'axios'
import { useHistory,useLocation } from "react-router-dom";
import {GlobalStateContext} from './globalDataContext'
import { useMediaQuery } from 'react-responsive'
import _ from "lodash";

class PejabatCard extends Component {
    constructor(props){
        super(props)
        this.state={
            nama:'',
            borderColor:'gainsboro',
            size:300,
            calon_id:[],
            url:''
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        console.log(nextProps)
        this.setState({borderColor:nextProps.borderColor,size:nextProps.size,url:nextProps.url})
    }
    // shouldComponentUpdate(n){
    //     console.log(n)
    // }
    // static getDerivedStateFromProps(nextProps,prevProps){
    //     console.log(nextProps)
    // }
    componentDidMount(){

    }

    render(){
        return(
            <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginRight:0,marginLeft:0,marginBottom:20}}>
                <div onClick={this.props.onPress}
                    style={{width:this.state.size,height:this.state.size,borderWidth:3,borderColor:this.state.borderColor,borderRadius:25,display:'flex',borderStyle:'solid',justifyContent:'center',alignItems:'center',flexDirection:'column',overflow:'hidden',backgroundColor:'#fff'}}>
                    <div style={{width:'100%',flex:2,backgroundImage:'url("'+this.state.url+'/storage/calon/'+this.props.gambar+'")',backgroundPositionX:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',borderTopLeftRadius:0,position:'relative'}}>
                        <div style={{display:'flex',width:50,height:50,backgroundColor:'orange',borderRadius:10,position:'absolute',top:10,left:10,justifyContent:'center',alignItems:'center'}}>
                            <h2 style={{margin:0,color:'#fff'}}>{this.props.nomor}</h2>
                        </div>
                        {this.state.borderColor=='dodgerblue'&&<div style={{display:'flex',width:50,height:50,borderRadius:10,position:'absolute',top:10,right:10,justifyContent:'center',alignItems:'center'}}>
                            <img src={require('../Image/guarantee.png')} style={{width:40}}/>
                        </div>}
                    </div>
                    <div style={{display:'flex',flex:1,width:'100%',flexDirection:'row'}}>
                        <div style={{display:'flex',flex:1,height:'100%',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:0}}>
                            <img src={require('../Image/voting.png')} style={{width:50}}/>
                        </div>
                        <div style={{display:'flex',flex:2,height:'100%',paddingright:10,paddingLeft:10,flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                            <h3 style={{margin:0,color:'dodgerblue'}}>{this.props.nama}</h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ItemCalon     =   ({nama,nomor,id,parentSetter,image,selectedStatus})=>{
    const [selectedId,setSelectedId]    =   useState()
    useEffect(()=>{
        parentSetter(selectedId)
    },[parentSetter,selectedId])

    return(
            <div onClick={()=>parentSetter(id)} style={{display:'flex',height:selectedStatus?80:70,width:selectedStatus?'95%':'90%',backgroundColor:selectedStatus?'dodgerblue':'aliceblue',flexDirection:'row',alignItems:'center',justifyContent:'center',borderRadius:10,marginTop:10}}>
                <div style={{display:'flex',flex:1,height:'100%',alignItems:'center',justifyContent:'flex-end'}}>
                    {/* <img src={`https://dashboard.pemirama.untan.ac.id/storage/calon/${image}`} style={{width:55,borderRadius:8}}/> */}
                    <img src={`${process.env.REACT_APP_API_URL}/storage/calon/${image}`} style={{width:55,borderRadius:8}}/>
                </div>
                <div style={{display:'flex',flex:3,height:'100%',alignItems:'center',justifyContent:'center'}}>
                    <h1 style={{color:selectedStatus?'#fff':'darkslategray',marginLeft:10,fontSize:15}}>
                        {nama}
                    </h1>
                </div>
                <div style={{display:'flex',flex:1,height:'100%',alignItems:'center',justifyContent:'flex-start'}}>
                    <div style={{display:'flex',width:55,height:55,alignItems:'center',justifyContent:'center',backgroundColor:selectedStatus?'aliceblue':'dodgerblue',borderRadius:10}}>
                        <h1 style={{color:selectedStatus?'dodgerblue':'lightcyan',fontSize:20}}>
                            {`0${nomor}`}
                        </h1>
                    </div>
                </div>
            </div>
    )
}

const PartaiCard    =   ({item,parentSetter,selectedByParent})=>{
    const [itemList,setItem]    =   useState(item)
    const [selected, setselected] = useState(selectedByParent)
    
    useEffect(()=>{
        setItem(item)
    },[item])

    useEffect(()=>{
        parentSetter(selected)
    },[selected])

    useEffect(()=>{
        setselected(selectedByParent)
    },[selectedByParent])

    const wrapperSetParentState = useCallback(
        val => {
        //   console.log(`selected ${val}`)
          setselected(val)
        },
        [setselected],
    )
    return(
        <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginRight:0,marginLeft:0,marginBottom:20}}>
            <div style={{width:350,borderWidth:2.5,borderColor:'dodgerblue',borderRadius:25,display:'flex',borderStyle:'solid',justifyContent:'flex-start',flexDirection:'column',overflow:'hidden',backgroundColor:'#fff'}}>
                <div style={{height:80,backgroundColor:'aliceblue',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',padding:15}}>
                    <img src={require('../Image/correct.png')} style={{width:65}}/>
                    <h1 style={{color:'darkslategray',marginLeft:10,fontSize:25}}>
                        {itemList.partai}
                    </h1>
                </div>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',marginTop:10,marginBottom:20}}>
                    {itemList.data.map((i,x)=>{
                        // console.log(selected,i.id)
                        return(
                            <ItemCalon 
                            selectedStatus={selected===i.id?true:false} 
                            parentSetter={wrapperSetParentState} 
                            key={x} 
                            id={i.id} nama={i.nama} nomor={i.nomor} image={i.gambar}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function Dpm(){

    const [state,setState]  =   useState({
            c:'gainsboro',
            pejabatList:[
       
            ],
            temp:[],
            calon_id:null,
            nim:'',
            fakultas:null,
    })
    const [stateReducer, dispatch] = useContext(GlobalStateContext);
    const [calonByPartai, setCalon] = useState([]);
    const [selected, setselected] = useState(null)
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 })
    const params    =   useLocation()
    const history   =   useHistory()
    useEffect(()=>{
        console.log(params.state)
        if(!params.state){
            if (window.confirm('Mohon Login Terlebih Dahulu'))
            {
                history.push('/')
            }
            else
            {
                // They clicked no
            }
        }else{
            setState({...state,nim:params.state.nim,fakultas:params.state.fakultas,nama:params.state.nama})
            console.log(state)
        }
    },[state.nim,state.fakultas])
    useEffect(()=>{
        if(!params.state){
            if (window.confirm('Mohon Login Terlebih Dahulu'))
            {
                history.push('/')
            }
            else
            {
                // They clicked no
            }
        }else{
            console.log(state)
            // const   parFak  =   'Fakultas Teknik'
            const   parFak  =   params.state.fakultas 
            axios.post(stateReducer.usedUrl+'/api/dpm',{fakultas:parFak}).then(res=>{
                console.log(res.data)
                if(res.data.length<=1){
                    alert('Kandidat tidak ada atau Aklamasi')
                    history.push('/waiting')
                }else{
                    const temp=[]
                    res.data.map((item)=>{
                        temp.push({id:item.id,nama:item.nama,nim:item.nim,nomor:item.nomor,borderColor:'whitesmoke',size:300,gambar:item.gambar})
                    })
                    setState({...state,pejabatList:temp})
                    // const groupByPartai =   _.groupBy(res.data,'partai')
                    // // console.log(groupByPartai)
                    // let tempPartai    =   []
                    // for (const [key, value] of Object.entries(groupByPartai)) {
                    //     // console.log({partai:key,data:value});
                    //     tempPartai = tempPartai.concat([{partai:key,data:value}])
                    // }
                    // console.log(tempPartai)
                    // setCalon(tempPartai)
                }
            }).catch(err=>{
                console.log({err})
            })
        }
    },[])
    const vote  =   ()=>{
        if(selected==null){
            alert('Pilih Dulu Calonnya')
        }else{
            console.log(state.calon_id)
            axios.post(stateReducer.usedUrl+'/api/vote',{
                nim:state.nim,
                // kategori:2,
                calon_id:state.calon_id,
                // calon_id:selected,
                fakultas_id:params.state.fakultas_id
                // token:stateReducer.datatps.token,
            }).then(res=>{
                console.log(res.data)
                if(res.data.success==true){
                    // if (window.confirm('Data Pilihanmu Sudah Masuk, Anda akan terlogout Otomatis. Terima Kasih'))
                    // {
                        history.push('/waiting')
                    // }
                    // else
                    // {
                    //     // They clicked no
                    // }
                }
            }).catch(err=>{
                console.log({err})
                if(err.response.data.error.nim[0]=="The nim has already been taken."){
                    alert('Anda sudah Memilih, Anda Akan di teruskan ke Halaman Selanjutnya')
                    history.push('/waiting')
                }else{
                    alert('Terjadi Masalah.')
                }
            })
        }
    }
    const changeActiveRadioButton=(index)=>
    {
        state.pejabatList.map(( item )=>{
            // item.bgcolor = 'white';
            // item.bdwidth = 2;
            item.borderColor = 'whitesmoke';
            item.size = 300;
        });
        // const temp  =   [];
        // console.log(this.state.temp.includes(index),index)
        // if(this.state.temp.includes(index)==true){
        //     var index = this.state.temp.indexOf(index);
        //     if (index > -1) {
        //        this.state.temp.splice(index, 1);
        //     }
        //     console.log(this.state.temp)
        //     this.state.calon_id.length=0
        //     this.state.temp.map(item=>{
        //         this.state.calon_id.push(this.state.pejabatList[item].id)
        //         this.state.pejabatList[item].borderColor = 'dodgerblue';
        //         this.state.pejabatList[item].size = 350;
        //     })
        //     console.log(this.state.calon_id)
        //     this.props.history.push('/dpm')
        // }else{
        //     this.state.temp.push(index)
        //     console.log(this.state.temp)
        //     if(this.state.temp.length===3){
        //         this.state.temp.shift()
        //         console.log(this.state.temp)
        //         this.state.calon_id.length=0
        //         this.state.temp.map(item=>{
        //             this.state.calon_id.push(this.state.pejabatList[item].id)
        //             this.state.pejabatList[item].borderColor = 'dodgerblue';
        //             this.state.pejabatList[item].size = 350;
        //         })
        //         console.log(this.state.calon_id)
        //         this.props.history.push('/dpm')
        //     }else{
        //         this.state.temp.map(item=>{
        //             this.state.pejabatList[item].borderColor = 'dodgerblue';
        //             this.state.pejabatList[item].size = 350;
        //         })
        //         this.props.history.push('/dpm')
        //     }
        // }
        state.pejabatList[index].borderColor = 'dodgerblue';
        state.pejabatList[index].size = 350;
        setState({...state,calon_id:state.pejabatList[index].id})
        // console.log({calon_id:state.pejabatList[index].id});
        setselected(state.pejabatList[index].id)
    }
    // render() {
        const wrapperSetParentState = useCallback(
            val => {
                setselected(val)
                // console.log(`selected on parent ${val}`)
            //   setCalon(calonByPartai)
            },
            [setselected],
        )
        return (
        <div style={{display:'flex',minHeight:'100vh',flexDirection:'column',backgroundColor:'aliceblue'}}>
            <div style={{display:'flex',flex:1,flexDirection:'row',justifyContent:'center',marginTop:30,marginBottom:30}}>
                <div style={{display:'flex',width:'80%',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <div style={{display:'flex',flexDirection:isTabletOrMobile?'column':'row',alignSelf:'start'}}>
                        <h2 style={{color:'darkslategray',margin:0,fontWeight:'200'}}>
                            Hi, 
                        </h2>
                        <h2 style={{color:'dodgerblue',margin:0}}>
                            {state.nama}
                        </h2>
                    </div>
                    <h1 style={{color:'darkslategray',margin:0,alignSelf:'start',fontSize:30}}>
                        Partisipasi Kamu Menentukan Arah Bangsa.
                    </h1>
                </div>
            </div>
            <div style={{display:'flex',flex:4,flexDirection:'column',alignItems:'center'}}>
                <div style={{display:'flex',flex:1,width:'90%',flexDirection:'column',backgroundColor:'#fff',borderRadius:20}}>
                    <h4 style={{color:'darkslategray',margin:20,fontWeight:'500',alignSelf:'center'}}>
                        Kadidat DPM {state.fakultas}
                    </h4>
                    <div style={{display:'flex',flex:1,width:'100%',flexDirection:'row',flexWrap:'wrap'}}>
                        {state.pejabatList.map((item,key)=>{
                            return(
                                <PejabatCard
                                    key={key}
                                    onPress={changeActiveRadioButton.bind(this,key)}
                                    nama={item.nama}
                                    borderColor={item.borderColor}
                                    size={item.size}
                                    nomor={item.nomor}
                                    gambar={item.gambar}
                                    url={stateReducer.usedUrl}
                                />
                            )
                        })}
                    </div>
                    <div style={{display:'flex',flex:1,width:'100%',flexDirection:'row',flexWrap:'wrap'}}>
                        {calonByPartai.map((i,index)=>{
                            return(
                                <PartaiCard selectedByParent={selected} parentSetter={wrapperSetParentState} key={index} item={i}/>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div style={{display:'flex',flex:1,justifyContent:isTabletOrMobile?'center':'flex-end',alignItems:'center',paddingRight:isTabletOrMobile?0:30,marginBottom:30}}>
                <button 
                    onClick={()=>{
                        // console.log(selected);
                        vote()
                        // this.props.history.push('/dpm',{fakultas:'Fakultas Hukum'})
                    }}
                    style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',padding:0,width:300,height:50,marginTop:30,backgroundColor:'dodgerblue',color:'white',borderRadius:10,fontSize:16,borderWidth:'inherit'}}>
                        <h3 style={{color:'#fff',margin:10,fontSize:15}}>
                            Selanjutnya
                        </h3>
                        <img src={require('../Image/next.png')} style={{width:35}}/>
                        <img src={require('../Image/next.png')} style={{width:35}}/>
                </button>  
            </div>
        </div>
        )
    // }
}

export default Dpm;