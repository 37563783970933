import React, { Component, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import { GlobalStateContext } from './globalDataContext'
import { useMediaQuery } from 'react-responsive'

class PejabatCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nama: '',
      borderColor: 'whitesmoke',
      size: 300,
      gambar: '19.jpg',
      url: '',
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    this.setState({
      borderColor: nextProps.borderColor,
      size: nextProps.size,
      gambar: nextProps.gambar,
      url: nextProps.url,
    })
  }
  // shouldComponentUpdate(n){
  //     console.log(n)
  // }
  // static getDerivedStateFromProps(nextProps,prevProps){
  //     console.log(nextProps)
  // }
  componentDidMount() {}
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 20,
        }}
      >
        <div
          onClick={this.props.onPress}
          style={{
            width: this.state.size,
            height: this.state.size,
            borderWidth: 3,
            borderColor: this.state.borderColor,
            borderRadius: 25,
            display: 'flex',
            borderStyle: 'solid',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              width: '100%',
              flex: 2,
              backgroundImage:
                'url("' +
                this.state.url +
                '/storage/calon/' +
                this.props.gambar +
                '")',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderTopLeftRadius: 0,
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 50,
                height: 50,
                backgroundColor: 'orange',
                borderRadius: 10,
                position: 'absolute',
                top: 10,
                left: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2 style={{ margin: 0, color: '#fff' }}>{this.props.nomor}</h2>
            </div>
            {this.state.borderColor == 'dodgerblue' && (
              <div
                style={{
                  display: 'flex',
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={require('../Image/guarantee.png')}
                  style={{ width: 40 }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
              }}
            >
              <img src={require('../Image/voting.png')} style={{ width: 50 }} />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 2,
                height: '100%',
                paddingright: 10,
                paddingLeft: 10,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <h3 style={{ margin: 0, color: 'dodgerblue' }}>
                {this.props.nama}
              </h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function Home() {
  const [state, setState] = useState({
    c: 'gainsboro',
    pejabatList: [],
    nim: null,
    fakultas: null,
    calon_id: null,
    nama: '',
  })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 })
  const [stateReducer, dispatch] = useContext(GlobalStateContext)
  const params = useLocation()
  const history = useHistory()
  const vote = () => {
    if (state.calon_id == null) {
      alert('Pilih Dulu Calonnya')
    } else {
      console.log(state)
      axios
        .post(stateReducer.usedUrl + '/api/vote', {
          nim: state.nim,
          // kategori:1,
          calon_id: state.calon_id,
          fakultas_id: params.state.fakultas_id,
          // token:stateReducer.datatps.token,
        })
        .then((res) => {
          console.log(res.data)
          if (res.data.success == true) {
            history.push('/presma-fakultas', {
              fakultas: params.state.fakultas,
              nim: params.state.nim,
              nama: params.state.nama,
              fakultas_id: params.state.fakultas_id,
            })
          }
        })
        .catch((err) => {
          console.log({ err })
          if (
            err.response.data.error.nim[0] == 'The nim has already been taken.'
          ) {
            alert(
              'Anda sudah Memilih, Anda Akan di teruskan ke Halaman memilih DPM'
            )
            history.push('/presma-fakultas', {
              fakultas: state.fakultas,
              nim: state.nim,
              nama: state.nama,
              fakultas_id: params.state.fakultas_id,
            })
          } else {
            alert('Terjadi Masalah.')
          }
        })
    }
  }

  useEffect(() => {
    axios
      .get(stateReducer.usedUrl + '/api/presiden')
      .then((res) => {
        console.log('cek presma', res.data)
        if (res.data.length === 0) {
          history.push('/presma-fakultas', {
            fakultas: params.state.fakultas,
            nim: params.state.nim,
            nama: params.state.nama,
            fakultas_id: params.state.fakultas_id,
          })
        }
        const temp = []
        res.data.map((item) => {
          temp.push({
            id: item.id,
            nama: item.nama,
            nim: item.nim,
            nomor: item.nomor,
            borderColor: 'whitesmoke',
            gambar: item.gambar,
            size: 300,
          })
        })
        // res.data.map((item)=>{
        //     temp.push({id:item.id,nama:item.nama,nim:item.nim,nomor:item.nomor,borderColor:'gainsboro',gambar:item.gambar,size:300})
        // })
        setState({ ...state, pejabatList: temp })
        console.log(state.pejabatList)
      })
      .catch((err) => {
        console.log({ err })
      })
  }, [])
  useEffect(() => {
    console.log(params.state)
    if (!params.state) {
      if (window.confirm('login dulu Bro...')) {
        history.push('/')
      } else {
      }
    } else {
      setState({
        ...state,
        nim: params.state.nim,
        fakultas: params.state.fakultas,
        nama: params.state.nama,
      })
      console.log(state)
    }
  }, [state.nim, state.fakultas])
  const changeActiveRadioButton = (index) => {
    state.pejabatList.map((item) => {
      // item.bgcolor = 'white';
      // item.bdwidth = 2;
      item.borderColor = 'whitesmoke'
      item.size = 300
    })
    state.pejabatList[index].borderColor = 'dodgerblue'
    state.pejabatList[index].size = 350
    setState({ ...state, calon_id: state.pejabatList[index].id })
    // this.props.history.push('/dashboard')
    // this.state.pejabatList[index].bdwidth = 1;
    // this.state.pejabatList[index].bgtext = 'white';
    // console.log(this.state.pejabatList)
    // this.setState({ priceItem: this.state.priceItem },()=>{
    //     this.setState({ selectedItem: this.state.priceItem[index].id,harga: this.state.priceItem[index].price});
    // });
  }
  // render() {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundColor: 'aliceblue',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '80%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isTabletOrMobile ? 'column' : 'row',
              alignSelf: 'start',
            }}
          >
            <h2
              style={{ color: 'darkslategray', margin: 0, fontWeight: '200' }}
            >
              Selamat Datang,
            </h2>
            <h2 style={{ color: 'dodgerblue', margin: 0 }}>{state.nama}</h2>
          </div>
          <h1
            style={{
              color: 'darkslategray',
              margin: 0,
              alignSelf: 'start',
              fontSize: 30,
            }}
          >
            Pilih Kandidat pilihanmu, Pilihanmu Menentukan Dunia.
          </h1>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 4,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '90%',
            flexDirection: 'column',
            backgroundColor: '#fff',
            borderRadius: 20,
          }}
        >
          <h4
            style={{
              color: 'darkslategray',
              margin: 20,
              fontWeight: '500',
              alignSelf: 'center',
            }}
          >
            Kandidat Presma Wapresma Universitas Tanjungpura
          </h4>
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {state.pejabatList.map((item, key) => {
              return (
                <PejabatCard
                  key={key}
                  onPress={changeActiveRadioButton.bind(this, key)}
                  nama={item.nama}
                  borderColor={item.borderColor}
                  size={item.size}
                  nomor={item.nomor}
                  gambar={item.gambar}
                  url={stateReducer.usedUrl}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: isTabletOrMobile ? 'center' : 'flex-end',
          alignItems: 'center',
          paddingRight: isTabletOrMobile ? 0 : 30,
          marginBottom: 30,
        }}
      >
        <button
          onClick={() => {
            console.log('test')
            vote()
            // this.props.history.push('/dpm',{fakultas:'Fakultas Hukum'})
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            width: 300,
            height: 50,
            marginTop: 30,
            backgroundColor: 'dodgerblue',
            color: 'white',
            borderRadius: 10,
            fontSize: 16,
            borderWidth: 'inherit',
          }}
        >
          <h3 style={{ color: '#fff', margin: 10, fontSize: 15 }}>
            Selanjutnya
          </h3>
          <img src={require('../Image/next.png')} style={{ width: 35 }} />
          <img src={require('../Image/next.png')} style={{ width: 35 }} />
        </button>
      </div>
    </div>
  )
  // }
}

export default Home
