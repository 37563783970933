import React,{useContext} from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import CameraWeb from './Screen/Camera'
import Login from './Screen/Login'
import Home from './Screen/Home'
import Dpm from './Screen/DpmFakultas'
import DpmUntan from './Screen/DpmUntan'
import PresmaFakultas from './Screen/PresmaFakultas'
import Waiting from './Screen/Waiting'
import noAccess from './Screen/noAccess'
import Setting from './Screen/Setting'
import PdfView from './Screen/PdfRes'
import { GlobalStateProvider } from "./Screen/globalDataContext";
// import {GlobalStateContext} from "./Screen/globalDataContext";

function App() {
  // const globalState = useGlobalState();
  // const datatps = globalState.datatps;
  // console.log(datatps)
  // const [state, dispatch] = useContext(GlobalStateContext);
  return (
    <GlobalStateProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/foto-pemilih" exact component={CameraWeb} />
          <Route path="/dashboard" component={Home} />
          <Route path="/dpm" component={Dpm} />
          <Route path="/presma-fakultas" component={PresmaFakultas} />
          <Route path="/dpm-untan" component={DpmUntan} />
          <Route path="/waiting" component={Waiting} />
          {/* <Route path="/no-access" component={noAccess} /> */}
          <Route path="/setting" component={Setting} />
          <Route path="/pdf" component={PdfView} />
        </Switch>
      </Router>
    </GlobalStateProvider>
  );
}
// GENERATE_SOURCEMAP=false 

export default App;
// idmhs: 100329
// idprogdi: 218
// idprogram: 1
// idperiode: 365
// statakd: 0
// nama: "BAGUS JATI KUNCORO"
// progdi: "Sistem Komputer"
// fakultas: "Fakultas MIPA"
// ips: 0
// maxsks: 12
// ipk: 3.27999997138977
// thakad: "2019/2020"
// smt: 2
// iden: 6931
// username: "H1051131061"
// passwd: "xbearbrand"