import React, { Component, useContext, useEffect, useState } from 'react'
import a from '../Image/hugo-message-sent.png'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
import { GlobalStateContext } from './globalDataContext'
import useGlobalState from './globalDataContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { DeviceUUID } from 'device-uuid'

function Login() {
  const [state, setState] = useState({
    nim: null,
    password: null,
    tpsname: '',
    a: true,
    count: 0,
  })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 })
  const [flexIsMobile, setFlex] = useState(2)
  const [flexDisMobile, setFlexD] = useState('column')
  const [heightIsMobile, setHight] = useState('100vh')
  const [stateReducer, dispatch] = useContext(GlobalStateContext)
  let history = useHistory()
  const getUid = () => {
    var navigator_info = window.navigator
    var screen_info = window.screen
    var uid = navigator_info.mimeTypes.length
    uid += navigator_info.userAgent.replace(/\D+/g, '')
    uid += navigator_info.plugins.length
    uid += screen_info.height || ''
    uid += screen_info.width || ''
    uid += screen_info.pixelDepth || ''
    console.log(uid)
    console.log(new DeviceUUID().get())
  }

  useEffect(() => {
    // if(stateReducer.datatps==undefined){
    //     // alert('Ada Data');
    //     // alert('PC Station Belum Terdaftar pada TPS Mana pun. Mohom Segera Atur, Hubungi Petugas TPS')
    //     setState({...state,a:false,tpsname:'STATION NOT REGISTERED !!!'})
    //     console.log(state)
    // }else{
    Axios.get(stateReducer.usedUrl + '/api/countVote?').then((res) => {
      console.log(res.data)
      setState({ ...state, count: res.data })
    })
    // console.log(stateReducer)
    // }
    // dispatch({type:'RESET',payload:{data:'hebat'}})
    console.log(stateReducer)
    getUid()
  }, [state.tpsname])

  const login = () => {
    // console.log(datatps)
    const k = this
    console.log({ nim: state.nim, password: state.password })
    Axios.post(stateReducer.usedUrl + '/api/loginmhs', {
      nim: state.nim,
      password: state.password,
    })
      .then((res) => {
        console.log(res.data)
        if (res.data.idmhs == '0') {
          alert('Login Salah atau Status akademik tidak aktif')
        } else {
          // this.props.history.push('/dashboard',{nim:res.data.username,fakultas:res.data.fakultas})
          if (res.data.statakd == 0) {
            alert('Status Akademik anda tidak aktif.')
          }
          // else if(res.data.fakultas !== stateReducer.datatps.data.fakultas){
          //     alert('Anda Tidak Dapat Terdaftar di Fakultas Ini, Lakukan Pemilihan di Fakultas Anda Masing Masing.')
          // }
          else {
            const data = res.data
            // dispatch({type:'ADD_DATA_TPS',payload:data})
            history.push('/foto-pemilih', {
              nim: res.data.username,
              nama: res.data.nama,
              fakultas: res.data.fakultas,
              fakultas_id: res.data.fakultas_id,
            })
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          alert('Anda Tidak Terdaftar di DPT.')
        } else {
          alert('Terjadi masalah saat Login.')
        }
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        backgroundPositionX: 'center',
        backgroundPositionY: 120,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundColor: 'aliceblue',
        backgroundImage: isTabletOrMobile
          ? `url(${require('../Image/loginIlus.png')})`
          : 'unset',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: isTabletOrMobile ? 'column' : 'row',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: isTabletOrMobile ? 2 : 5,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: isTabletOrMobile ? 0 : 50,
        }}
      >
        {isTabletOrMobile && (
          <div
            style={{
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 0,
            }}
          >
            <h2
              style={{
                color: 'darkslategray',
                margin: 0,
                fontWeight: '300',
                alignSelf: 'start',
              }}
            >
              Selamat Datang di Sistem
            </h2>
            <h1 style={{ color: 'dodgerblue', margin: 0, alignSelf: 'start' }}>
              Pemirama Online Universitas Tanjungpura
            </h1>
          </div>
        )}
        {!isTabletOrMobile && (
          <div
            style={{
              display: 'flex',
              height: isTabletOrMobile ? 110 : 150,
              width: '90%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: '#407bfe',
              borderRadius: 20,
              borderWidth: 0,
              borderColor: 'gainsboro',
              borderStyle: 'solid',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <img
                src={require('../Image/guarantee.png')}
                style={{ width: 100 }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 3,
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h4 style={{ color: '#fff', margin: 0, fontWeight: '400' }}>
                Total Vote Masuk
              </h4>
              <h1 style={{ color: 'gold', margin: 0, fontSize: 50 }}>
                {state.count}
              </h1>
            </div>
          </div>
        )}
        {/* <div style={{display:'inline-block',overflow:'hidden',height:'80%',width:'100%',}}> */}
        {!isTabletOrMobile && (
          <img
            src={require('../Image/loginIlus.png')}
            style={{ width: '85%' }}
          />
        )}
        {/* </div> */}
      </div>
      <div
        style={{
          display: 'flex',
          flex: 5,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {isTabletOrMobile && (
          <div
            style={{
              display: 'flex',
              height: isTabletOrMobile ? 110 : 150,
              marginTop: 0,
              width: '90%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: '#407bfe',
              borderRadius: 20,
              marginBottom: 10,
              borderWidth: 0,
              borderColor: 'gainsboro',
              borderStyle: 'solid',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <img
                src={require('../Image/guarantee.png')}
                style={{ width: 80 }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 3,
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h4 style={{ color: '#fff', margin: 0, fontWeight: '400' }}>
                Total Vote Masuk
              </h4>
              <h1 style={{ color: 'gold', margin: 0, fontSize: 50 }}>
                {state.count}
              </h1>
            </div>
          </div>
        )}
        {!isTabletOrMobile && (
          <div
            style={{
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 0,
            }}
          >
            <h2
              style={{ color: 'darkslategray', margin: 0, alignSelf: 'start' }}
            >
              Selamat Datang di Sistem
            </h2>
            <h1 style={{ color: 'dodgerblue', margin: 0, alignSelf: 'start' }}>
              Pemirama Online Universitas Tanjungpura
            </h1>
            <h4
              style={{
                color: 'darkslategray',
                textAlign: 'center',
                fontWeight: '300',
                paddingLeft: 30,
                paddingRight: 30,
                marginTop: 40,
              }}
            >
              Silakan gunakan Akun Siakad anda untuk melanjutkan.
            </h4>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            height: 330,
            width: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#fff',
            borderRadius: 20,
            marginBottom: 50,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {/* <h3 style={{color:'dodgerblue'}}>
                            NIM
                        </h3> */}
            <input
              onChange={(e) => {
                setState({ ...state, nim: e.target.value })
              }}
              type="text"
              placeholder={'NIM Siakad'}
              name="nim"
              style={{
                paddingLeft: 5,
                fontSize: 15,
                height: 40,
                width: '70%',
                backgroundColor: '#fff',
                borderWidth: 2,
                borderColor: 'gainsboro',
                borderStyle: 'solid',
                borderRadius: 8,
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {/* <h3 style={{color:'dodgerblue'}}>
                            NIM
                        </h3> */}
            <input
              onChange={(e) => {
                setState({ ...state, password: e.target.value })
              }}
              type="password"
              placeholder={'Password'}
              name="nim"
              style={{
                paddingLeft: 5,
                fontSize: 15,
                height: 40,
                width: '70%',
                backgroundColor: '#fff',
                borderWidth: 2,
                borderColor: 'gainsboro',
                borderStyle: 'solid',
                borderRadius: 8,
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <button
              onClick={() => {
                console.log('test')
                login()
                // this.props.history.push('/dashboard')
              }}
              style={{
                padding: 0,
                width: '70%',
                height: 40,
                marginTop: 10,
                backgroundColor: 'dodgerblue',
                color: 'white',
                borderRadius: 8,
                fontSize: 16,
                borderWidth: 'inherit',
              }}
            >
              <h3 style={{ color: '#fff', margin: 0, fontSize: 15 }}>Login</h3>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
  // }
}

export default withRouter(Login)
