import React,{useContext} from 'react'
import { Document, Page, Text, View, StyleSheet,PDFViewer } from '@react-pdf/renderer';
import {GlobalStateContext} from './globalDataContext'
import { useLocation } from "react-router-dom";


const ResItem   =   ({no,nama,voteCount})=>{
    return(
            <View style={{height:25,width:'100%',flexDirection:'row'}}>
                <View style={{flex:1}}>
                <Text style={{fontSize:12}}>No Urut {no}</Text>
                </View>
                <View style={{flex:3}}>
                    <Text style={{fontSize:12}}>{nama}</Text>
                </View>
                <View style={{flex:1,alignItems:'flex-end'}}>
                    <Text style={{fontSize:12,fontWeight:'bold'}}>{voteCount}</Text>
                </View>
            </View>
    )
}

const PdfRes    =   ({stateReducer,params})  => {
    console.log(params)
    Array.prototype.sum = function (prop) {
        var total = 0
        for ( var i = 0, _len = this.length; i < _len; i++ ) {
            total += this[i][prop]
        }
        return total
    }
    return (
        <Document>
            <Page size="A4" style={{flex:1,paddingHorizontal:50,paddingVertical:30,alignItems:'center'}}>
                <View style={{height:50,width:'100%',backgroundColor:'#fff',borderRadius:10,marginBottom:0,justifyContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontSize:12}}>LAPORAN HASIL REKAP SUARA PEMIRAMA UNIVERSITAS TANJUNGPURA</Text>
                </View>
                <View style={{height:50,width:'100%',backgroundColor:'#f6f5fd',borderRadius:10,marginBottom:10,justifyContent:'space-between',alignItems:'center',padding:10,flexDirection:'row'}}>
                    {/* <Text style={{fontSize:13}}>{stateReducer.datatps.data.name}</Text>
                    <Text style={{fontSize:13}}> Total Pemilih : {params.state.count}</Text> */}
                </View>
                <View style={{width:'100%',backgroundColor:'#f6f5fd',borderRadius:20,padding :10,flexDirection:'column',marginBottom:10}}>
                    <View style={{flex:1,width:'100%',flexDirection:'row'}}>
                        <View style={{flex:1}}>
                            <Text style={{fontSize:12}}>Hasil Rekap :</Text>
                        </View>
                        <View style={{flex:1,alignItems:'flex-end'}}>
                            <View style={{height:'80%',width:80, backgroundColor:'crimson',justifyContent:'center',alignItems:'center',borderRadius:10}}>
                                <Text style={{fontSize:10,color:'#fff'}}>GUBERNUR</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flex:5,width:'100%',flexDirection:'column',padding:10}}>
                        {/* {params.state.hasilPresma.map(item=>{
                            return(
                                <ResItem key={item.nomor} no={item.nomor} nama={item.nama} voteCount={item.voteCount}/>
                            )
                        })} */}
                    </View>
                    <View style={{flex:2,width:'100%',flexDirection:'row',padding:10,borderTopWidth:1,borderColor:'darkslategray'}}>
                        <View style={{flex:1}}>
                            <Text style={{fontSize:12}}>Total   :</Text>
                        </View>
                        <View style={{flex:1,alignItems:'flex-end'}}>
                        {/* <Text style={{fontSize:16}}>{params.state.hasilPresma.sum('voteCount')}</Text> */}
                        </View>
                    </View>
                </View>
                <View style={{width:'100%',backgroundColor:'#f6f5fd',borderRadius:20,padding :10,flexDirection:'column'}}>
                    <View style={{flex:1,width:'100%',flexDirection:'row'}}>
                        <View style={{flex:1}}>
                            <Text style={{fontSize:12}}>Hasil Rekap :</Text>
                        </View>
                        <View style={{flex:1,alignItems:'flex-end'}}>
                            <View style={{height:'80%',width:80, backgroundColor:'crimson',justifyContent:'center',alignItems:'center',borderRadius:10}}>
                                <Text style={{fontSize:10,color:'#fff'}}>DPM</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flex:5,width:'100%',flexDirection:'column',padding:10}}>
                        {/* {params.state.hasilDPM.map(item=>{
                            return(
                                <ResItem key={item.nomor} no={item.nomor} nama={item.nama} voteCount={item.voteCount}/>
                            )
                        })} */}
                    </View>
                    <View style={{flex:2,width:'100%',flexDirection:'row',padding:10,borderTopWidth:1,borderColor:'darkslategray'}}>
                        <View style={{flex:1}}>
                            <Text style={{fontSize:12}}>Total   :</Text>
                        </View>
                        <View style={{flex:1,alignItems:'flex-end'}}>
                            {/* <Text style={{fontSize:16}}>{params.state.hasilDPM.sum('voteCount')}</Text> */}
                        </View>
                    </View>
                </View>
                <View style={{height:150,width:'100%',backgroundColor:'#fff',borderRadius:20,padding :10,flexDirection:'row',position:'absolute',bottom:30}}>
                    <View style={{flex:1}}>

                    </View>
                    <View style={{flex:1,flexDirection:'column',justifyContent:'space-between',alignItems:'center',paddingVertical:20}}>
                        <Text style={{fontSize:13}}>UNTAN,                    2020</Text>
                        <Text style={{fontSize:10}}> TTD Petugas TPS PEMIRAMA UNTAN</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

const PdfView    =   ()  =>  {
    const params    =   useLocation()
    const [stateReducer, dispatch] = useContext(GlobalStateContext);
    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <PDFViewer style={{flex:1,display:'flex',height:window.innerHeight-10,width:'90%'}}>
                <PdfRes stateReducer={stateReducer} params={params}/>
            </PDFViewer>
        </div>
    )

}

export default PdfView
