import React, { Component } from 'react'
import Speech from 'react-speech';
import UIfx from 'uifx'
import bellAudio from './tku.mp4'
import bellAudio2 from './sound.mp3'

export default class Waiting extends Component {
    componentDidMount(){
        const bell = new UIfx(bellAudio)
        const bell2 = new UIfx(bellAudio2)
        bell.play()
        const belli= setInterval(()=>{
            bell2.play()
        },2000)
        setTimeout(()=>{
            this.props.history.push('/')
            clearInterval(belli)
        },5000)
    }
    render() {
        return (
            <div style={{display:'flex',justifyContent:'flex-start',backgroundColor:'aliceblue',flexDirection:'column',alignItems:'center',width:'100%',height:'100vh'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'flex-start',height:700,width:'80%',backgroundColor:'#fff',marginTop:100,borderRadius:30}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                        <img src={require('../Image/success.gif')} style={{width:'80%',marginBottom:20}}/>
                        <h1 style={{margin:0,textAlign:'center',color:'darkslategray',fontWeight:'300'}}>Terima Kasih Telah Memilih. Anda Luar Biasa Hebat.</h1>
                    </div>
                </div>
            </div>
        )
    }
}
