import React, { createContext, useReducer, useContext } from 'react'
import { useLocalStorageReducer } from 'react-storage-hooks'
/* Action Types */
const ADD_IDENTITAS_DOSEN = 'ADD_IDENTITAS_DOSEN'
const ADD_DATA_TPS = 'ADD_DATA_TPS'
const RESET = 'RESET'

/* Define a context and a reducer for updating the context */

const url = {
  local: 'localhost:8000',
  server: process.env.REACT_APP_API_URL,
}
const initialState = {
  usedUrl: url.server,
}
export const GlobalStateContext = createContext()

const globalStateReducer = (state, action) => {
  // console.log(state)
  // localStorage.setItem('myData', JSON.stringify({state}));
  switch (action.type) {
    case RESET:
      return initialState
    case ADD_IDENTITAS_DOSEN:
      return {
        ...state,
        identitas: action.payload,
      }
    case ADD_DATA_TPS:
      return {
        ...state,
        datatps: action.payload,
      }
    default:
      return state
  }
}

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useLocalStorageReducer(
    'dataTps',
    globalStateReducer,
    initialState
  )
  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  )
}
/* 
Default export is a hook that provides a simple API for updating the global state. 
This also allows us to keep all of this state logic in this one file
*/

const useGlobalState = () => {
  const [state, dispatch] = useContext(GlobalStateContext)
  const addIdentitas = (identitas) => {
    dispatch({
      type: ADD_IDENTITAS_DOSEN,
      payload: identitas,
    })
  }
  const addTps = (datatps) => {
    dispatch({
      type: ADD_DATA_TPS,
      payload: datatps,
    })
  }
  return {
    addIdentitas,
    addTps,
    identitas: { ...state.identitas },
    datatps: { ...state.datatps },
  }
}

export default useGlobalState
