import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Camera, { DEVICE, FACING_MODE } from 'react-camera-ios'
import Modal from 'react-modal'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation } from 'react-router-dom'
import Webcam from 'react-webcam'
import { GlobalStateContext } from './globalDataContext'
// import 'react-camera-ios/build/styles.css';

function CameraWeb() {
  const videoConstraints = {
    // width: 350,
    // height: 720,
    aspectRatio: 0,
    facingMode: 'user',
  }
  const webcamRef = React.useRef(null)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 })
  const [imageSrc, setImageSrc] = useState('')
  const [isCapture, SetIsCapture] = useState(false)
  const [file, setFile] = useState(null)
  const [cekjanji, setcekjanji] = useState(true)
  const [isGoing, setIsGoing] = useState(false)
  const [Os, SetOs] = useState('')
  const params = useLocation()
  let history = useHistory()
  const [stateReducer, dispatch] = useContext(GlobalStateContext)
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImageSrc(imageSrc)
    SetIsCapture(true)
    console.log(imageSrc)
    const g = new FormData()
    g.append('nim', params.state.nim)
    urltoFile(imageSrc, 'nim.jpg', 'image/jpeg').then(function (file) {
      console.log(file)
      g.append('foto', file)
      setFile(g)
    })
  }, [webcamRef])

  const getOs = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }

    return os
  }

  useEffect(() => {
    console.log(getOs())
    SetOs(getOs())
  }, [])

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType })
      })
  }

  const handleInputChange = () => {
    setIsGoing(!isGoing)
    console.log(isGoing)
  }

  const send = () => {
    console.log(isGoing)
    if (isGoing == false) {
      alert(
        'Tidak dapat melanjutkan karena anda tidak menyetujui pernyataan sebagai pemilih.'
      )
    } else {
      Axios.post(stateReducer.usedUrl + '/api/fotoPemilih', file)
        .then((res) => {
          console.log(res)
          // if (res.data.status == 'success') {
          //   history.push('/dashboard', {
          //     nim: params.state.nim,
          //     nama: params.state.nama,
          //     fakultas: params.state.fakultas,
          //     fakultas_id: params.state.fakultas_id,
          //   });
          // } else {
          //   alert(res.data.message);
          //   history.push('/dashboard', {
          //     nim: params.state.nim,
          //     nama: params.state.nama,
          //     fakultas: params.state.fakultas,
          //     fakultas_id: params.state.fakultas_id,
          //   });
          // }
          if (res.data.status == 'success') {
            // history.push('/presma-fakultas', {
            history.push('/dashboard', {
              nim: params.state.nim,
              nama: params.state.nama,
              fakultas: params.state.fakultas,
              fakultas_id: params.state.fakultas_id,
            })
          } else {
            alert(res.data.message)
            // history.push('/presma-fakultas', {
            history.push('/dashboard', {
              nim: params.state.nim,
              nama: params.state.nama,
              fakultas: params.state.fakultas,
              fakultas_id: params.state.fakultas_id,
            })
          }
        })
        .catch((err) => {
          console.log({ err })
          alert('Terjadi Kesalahan')
        })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Modal
        isOpen={cekjanji}
        style={{
          content: {
            // color: 'lightsteelblue',
            borderRadius: 10,
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h2
            style={{ color: 'darkslategray', fontSize: 18, fontWeight: 'bold' }}
          >
            Informasi Sebelum Memilih
          </h2>
          <h3
            style={{ color: 'darkslategray', fontSize: 15, fontWeight: '400' }}
          >
            Demi keamanan dan sebagai bukti verifikasi pemilih maka, pemilih
            diwajibkan untuk mencamtumkan swafoto sebelum melakukan pemilihan.
            Swafoto dapat dilakukan setelah pemilih memberi izin akses Kamera
            pada Browser dan menyetujui pernyataan berikut
          </h3>
          <h2
            style={{
              color: 'indianred',
              fontSize: 15,
              fontWeight: '600',
              marginTop: 30,
              textAlign: 'center',
            }}
          >
            *Dengan ini saya menyatakan bahwa data pemilih adalah diri saya
            sendiri yang dapat dibuktikan dan dipertanggung jawabkan jika
            terjadi sesuatu diluar peraturan.
          </h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              name="isGoing"
              type="checkbox"
              checked={isGoing}
              onChange={handleInputChange}
            />
            <h2
              style={{
                color: 'darkslategray',
                fontSize: 15,
                fontWeight: '600',
                marginLeft: 5,
                textAlign: 'center',
              }}
            >
              Ya, Saya Menyetujui
            </h2>
          </div>
          <h2
            style={{
              color: 'indianred',
              fontSize: 15,
              fontWeight: '600',
              marginTop: 30,
              textAlign: 'center',
            }}
          >
            Note : Dianjurkan menggunakan peramban Google Chrome, Terkhusus
            Pengguna iOS diwajibkan Menggunakan Peramban Safari,
          </h2>
          <button
            onClick={() => {
              console.log('ulangi')
              setcekjanji(false)
            }}
            style={{
              padding: 0,
              width: '70%',
              height: 40,
              marginTop: 10,
              backgroundColor: 'gray',
              color: 'white',
              borderRadius: 8,
              fontSize: 16,
              borderWidth: 'inherit',
            }}
          >
            <h3 style={{ color: '#fff', margin: 0, fontSize: 15 }}>
              Kirim Pernyataan
            </h3>
          </button>
        </div>
      </Modal>
      <div>
        <h3 style={{ color: 'darkslategray', fontSize: 15, fontWeight: '400' }}>
          Silahkan Ambil Foto Sebelum Memilih
        </h3>
      </div>
      {!isCapture ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
            overflow: 'hidden',
            marginTop: 20,
          }}
        >
          {Os !== 'iOS' ? (
            <Webcam
              audio={false}
              height={'auto'}
              width={350}
              ref={webcamRef}
              mirrored
              screenshotFormat="image/jpeg"
              onUserMedia={() => {
                console.log('camera loaded', isGoing)
              }}
              videoConstraints={videoConstraints}
            />
          ) : (
            <Camera
              device={DEVICE.MOBILE}
              facingMode={FACING_MODE.USER}
              placement={'cover'}
              quality="1"
              onError={(error) => console.log(error)}
              onTakePhoto={(dataUrl) => {
                setImageSrc(dataUrl)
                SetIsCapture(true)
                console.log(dataUrl)
                const g = new FormData()
                g.append('nim', params.state.nim)
                urltoFile(imageSrc, 'nim.jpg', 'image/jpeg').then(function (
                  file
                ) {
                  console.log(file)
                  g.append('foto', file)
                  setFile(g)
                })
              }}
            />
          )}
        </div>
      ) : (
        <img
          src={imageSrc}
          style={{
            width: 300,
            height: 'auto',
            borderRadius: 12,
            marginTop: 30,
          }}
        />
      )}
      {!isCapture ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          {Os !== 'iOS' && (
            <button
              onClick={() => {
                console.log('test')
                capture()
                // this.props.history.push('/presma-fakultas')
              }}
              style={{
                padding: 0,
                width: '70%',
                height: 40,
                marginTop: 10,
                backgroundColor: 'dodgerblue',
                color: 'white',
                borderRadius: 8,
                fontSize: 16,
                borderWidth: 'inherit',
              }}
            >
              <h3 style={{ color: '#fff', margin: 0, fontSize: 15 }}>Cekrek</h3>
            </button>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <button
            onClick={() => {
              console.log('test')
              send()
            }}
            style={{
              padding: 0,
              width: '70%',
              height: 40,
              marginTop: 10,
              backgroundColor: 'dodgerblue',
              color: 'white',
              borderRadius: 8,
              fontSize: 16,
              borderWidth: 'inherit',
            }}
          >
            <h3 style={{ color: '#fff', margin: 0, fontSize: 15 }}>
              Pakai Foto Ini
            </h3>
          </button>
          <h3 style={{ color: 'darkslategray', fontSize: 15 }}>OR</h3>
          <button
            onClick={() => {
              console.log('ulangi')
              SetIsCapture(false)
            }}
            style={{
              padding: 0,
              width: '70%',
              height: 40,
              marginTop: 10,
              backgroundColor: 'gray',
              color: 'white',
              borderRadius: 8,
              fontSize: 16,
              borderWidth: 'inherit',
            }}
          >
            <h3 style={{ color: '#fff', margin: 0, fontSize: 15 }}>
              Ulangi Ambil Gambar
            </h3>
          </button>
        </div>
      )}
    </div>
  )
}

export default CameraWeb
